<template>
    <div>
      <div v-if="loading">
                        <div style="position: fixed; top:0; bottom:0; width:100%;background-color:#efefef;opacity:0.4;z-index:99;margin: 0 auto;text-align:center;">
            <img style="position:relative;top:45%;opacity:1;z-index:100;" src="../assets/images/loader.gif" alt="" />
            </div>
        </div>
      <div>
    <!-- <div class="slider" id="slider"  data-width="100">
    <button class="control_next">&rarr;</button><button class="control_prev">&larr;</button>
    <ul>
      <li class="actslide" v-for="(banner, index) in banners" v-bind:key="index">
        <div class="slide"><img :src="imgPath+banner.image"></div>
      </li>
    </ul>
    <div class="progress"><div class="bar"></div></div>
  </div> -->
  <!-- <div class="slider" id="slider"  data-width="100">
    <ul>
  <VueSlickCarousel :arrows="true" :dots="true" :autoplay="false" :autoplaySpeed="2000" v-if="banners.length">
    
     <li class="actslide" v-for="(banner, index) in banners" :key="index">
      <div class="slide"><img :src="imgPath+banner.image"></div>
    </li>
       </VueSlickCarousel>
       </ul>
</div> -->
<VueSlickCarousel :arrows="true" :dots="true" :autoplay="true" :autoplaySpeed="2000" v-if="banners.length">
    <div class="slide" v-for="(banner, index) in banners" :key="index"><img :src="imgPath+banner.image"></div>
  </VueSlickCarousel>
  <div class="cl"></div>
  <div class="main bgF6F9FC">
    <div class="container">
      <div class="row">
        <div class="col-md-12">     
          <div class="top-cat-sec">
            <div class="home-tit">
              <h2><span>Connect</span></h2>
              <!-- <p></p> -->
            </div>
            <div class="land-pack">
              <ul>
                <li>
                  <div class="land-pack-grid">
                    <div class="land-pack-grid-img">
                      <img src="./../assets/img/products/money.jpg" alt="">
                    </div>
                    <div class="land-pack-grid-text">
                      <h5>Money</h5>
                      <router-link to="/money" class="land-pack-grid-btn"></router-link>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="land-pack-grid">
                    <div class="land-pack-grid-img">
                      <img src="./../assets/img/machine.jpg" alt="">
                    </div>
                    <div class="land-pack-grid-text">
                      <h5>Machine</h5>
                      <router-link :to="{ name: 'MachineType' }" class="land-pack-grid-btn"></router-link>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="land-pack-grid">
                    <div class="land-pack-grid-img">
                      <img src="./../assets/img/material.jpeg" alt="">
                    </div>
                    <div class="land-pack-grid-text">
                      <h5>Material</h5>
                      <router-link to="/material-brand" class="land-pack-grid-btn"></router-link>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="land-pack-grid">
                    <div class="land-pack-grid-img">
                      <img src="./../assets/img/products/manpower.jpg" alt="">
                    </div>
                    <div class="land-pack-grid-text">
                      <h5>Manpower</h5>
                      <router-link to="/manpower" class="land-pack-grid-btn"></router-link>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="main">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="home-tit">
            <h2><span>Trade</span></h2>
            
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <div class="grid-item">
            <div class="imgholder ">
              <!-- <router-link v-bind:to="{ name: 'VirginPolymers', query: { name: 'Virgin Polymers' }}"> -->
                <a @click="viewTrade('Virgin Polymers')">
                <img src="../assets/img/trade1.jpg">
              </a>
            </div>
            <div class="product_footer text-center">
              <div class="grid_product_header">
                <span class="name">Virgin Polymers</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="grid-item">
            <div class="imgholder ">
             <a @click="viewTrade('Polymer Compounds')">
                <img src="../assets/img/trade2.jpg">
              </a>
            </div>
            <div class="product_footer text-center">
              <div class="grid_product_header">
                <span class="name">Polymer Compounds</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="grid-item">
            <div class="imgholder ">
            <a @click="viewTrade('Reprocessed Polymers')">
                <img src="../assets/img/trade3.jpg">
             </a>
            </div>
            <div class="product_footer text-center">
              <div class="grid_product_header">
                <span class="name">Reprocessed Polymers</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="grid-item">
            <a @click="viewTrade('Skrap Material')">
            <div class="imgholder ">
             
                <img src="../assets/img/scrap.jpg">
              
            </div>
            <div class="product_footer text-center">
              <div class="grid_product_header">
                <span class="name">Scrap Material</span>
              </div>
            </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="cl"></div>
  <!-- <div class="main">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="home-tit">
            <h2><span>Trade</span></h2>
            <p>Get the best suppliers, manufacturers and professionals.</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3" v-for="(product , index) in products" v-bind:key="index">
          <a @click="viewProduct(product.id)">
          <div class="grid-item">
            <div class="imgholder ">
              <img :src="imgPath+product.image1">
            </div>
            <div class="product_footer">
              <div class="grid_product_header">
                <span class="name">{{ product.name }} </span>
              </div>
              <div class="product_footer-info">
                <div class="product-price-grid"><i class="fa fa-inr"></i> {{ product.price }} {{ product.currency }} / MT </div>
                <div class="product-location-grid">{{ product.city }} , {{ product.state }}, {{ product.country.name }}</div>
              </div>
            </div>
          </div>
        </a>
        </div>
        
      </div>
    </div>
  </div> -->
  <div class="cl"></div>
  <div class="main">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
            <div class="home-tit">
              <h2><span>Educate</span></h2>
            </div>
            <div class="home-city">
              <ul>
                <li>
                  <div class="hcity">
                    <div>
                      <img src="./../assets/img/products/1.jpg" alt="Machine Manufacturers">
                    </div>
                    <div>
                      <!-- <img src="./../assets/img/products/1.jpg" alt="Machine Manufacturers"> -->
                      <h4>Know Your Polymer</h4>
                    </div>
                    <router-link to="/know-your-polymer" class="fclick">&nbsp;</router-link>
                  </div>
                </li>
                <li>
                  <div class="hcity">
                    <div>
                      <img src="./../assets/img/products/12.jpg" alt="Packaging and Labeling">
                    </div>
                    <div>
                      <!-- <img src="./../assets/img/products/12.jpg" alt="Packaging and Labeling"> -->
                      <h4>Know Your Product Composition</h4>
                    </div>
                    <router-link to="/know-your-product" class="fclick">&nbsp;</router-link>
                  </div>
                </li>
                <li>
                  <div class="hcity">
                    <div>
                      <img src="./../assets/img/products/13.jpg" alt="Oil, Lubricants, Grease">
                    </div>
                    <div>
                      <!-- <img src="./../assets/img/products/13.jpg" alt="Oil, Lubricants, Grease"> -->
                      <h4>Blogs</h4>
                    </div>
                    <router-link to="/blogs" class="fclick">&nbsp;</router-link>
                  </div>
                </li>
                <li>
                  <div class="hcity">
                    <div>
                      <img src="./../assets/img/products/14.jpg" alt="Electrical and Electronics">
                    </div>
                    <div>
                      <!-- <img src="./../assets/img/products/14.jpg" alt="Electrical and Electronics"> -->
                      <h4>Q & A</h4>
                    </div>
                    <router-link to="/qa" class="fclick">&nbsp;</router-link>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="blog-sec">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="home-tit">
            <h2><span>Blogs</span></h2>
            
          </div>
          <div class="hom-event">
            <div class="hom-eve-com hom-eve-lhs">
              <div class="hom-eve-lhs-1 col-md-3" v-for="(blog , index) in blogs" v-bind:key="index">
                <div class="eve-box">
                  <div>
                    <a @click="viewBlog(blog.id)">
                      <img :src="imgPath+blog.image" alt="">
                      <span>{{ blog.created_at | formatDate }}</span>
                    </a>
                  </div>
                  <div>
                    <h4><a @click="viewBlog(blog.id)">{{ blog.title }}</a></h4>
                    <span class="addr">{{ blog.writer }}</span>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <!-- <div class="feedback">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h2 class="heading text-center">WHAT DO OUR CLIENT SAY </h2>
          <div class="title-divider">
            <ul>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
            </ul>
          </div>
        </div>
        <div class="col-md-12">
          
          <div id="testimonial-slider" class="owl-carousel">
            <VueSlickCarousel2 :arrows="true" :dots="true" :autoplay="false" :autoplaySpeed="2000">
            <div class="testimonial">
              <p class="description">
                "very Good Service"
              </p>
              <div class="pic">
                <img src="./../assets/img/img-3.jpg" alt="">
              </div>
              <h4 class="testimonial-title">
                Raj
                <small class="post"> - Web Developer</small>
              </h4>
            </div>
            <div class="testimonial">
              <p class="description">
                "working here can be challenging but helps building your professionalism and working attitude. Work life balance is good and flexible working allowed so all in all a good place to work"
              </p>
              <div class="pic">
                <img src="./../assets/img/img-3.jpg" alt="">
              </div>
              <h4 class="testimonial-title">
                williamson
                <small class="post"> - Web Developer</small>
              </h4>
            </div>
            <div class="testimonial">
              <p class="description">
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed lobortis odio ac eros sollicitudin consequat. Proin a tortor tortor. Nullam consequat dictum metus in vulputate"
              </p>
              <div class="pic">
                <img src="./../assets/img/img-2.jpg" alt="">
              </div>
              <h4 class="testimonial-title">
                kristiana
                <small class="post"> - Web Designer</small>
              </h4>
            </div>
            </VueSlickCarousel2>
          </div>
          
        </div>
      </div>
    </div>
  </div> -->
  </div>
</template>
<script>
import VueSlickCarousel from 'vue-slick-carousel'
//import VueSlickCarousel2 from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
export default {
        components: { 
          VueSlickCarousel,
          //VueSlickCarousel2 
        },
        metaInfo: {
      title: 'Home'
    },
    data() {
        return {
            products: [],
            blogs: [],
            banners: [],
            imgPath: process.env.VUE_APP_API_URL,
            imgPathLocal: process.env.VUE_APP_URL,
            loading: false
        }
    },
    created() {
        this.loading = true
        this.axios
        .get(process.env.VUE_APP_API_URL+"api/front")
        .then(response => {
            this.products = response.data.products;
            this.blogs = response.data.blogs;
        })
        .finally(() => this.loading = false);
        this.axios
        .get(process.env.VUE_APP_API_URL+"api/banners")
        .then(response => {
            this.banners = response.data.banners;
        });
    },
    methods: {
            viewTrade(id) {
              sessionStorage.setItem("Tradecategory", id);
              this.$router.push({ name: "VirginPolymers" });
            },
             viewProduct(id) {
              sessionStorage.setItem("productId", id);
              this.$router.push({ name: "TradeProductDetails" });
            },
            viewBlog(id) {
              sessionStorage.setItem("blogId", id);
              this.$router.push({ name: "BlogDetails" });
            }
        }
};
</script>