<template>
    <div>
        
<div class="main bgF6F9FC">
  <div class="container">
    <div class="row">
      <div class="col-md-12">     
        <div class="top-cat-sec">
          <div class="home-tit">
            <h2><span>Manpower({{ countryType }})</span></h2>
          </div>
          <div class="land-pack">
            <ul>
              <li v-for="manpower in manpowers" :key="manpower.name">
                <div class="land-pack-grid">
                  <div class="land-pack-grid-img">
                    <img :src="imagePath+manpower.image" alt="">
                  </div>
                  <div class="land-pack-grid-text">
                    <h5>{{ manpower.name }}</h5>
                    
                    <router-link v-if="manpower.name=='Executive'" to="/manpower-executives-category" class="land-pack-grid-btn"></router-link>
                    <router-link v-else-if="manpower.name=='Labour'" to="/manpower-labours" class="land-pack-grid-btn"></router-link>
                    <router-link v-else to="/manpower/institutional-placements" class="land-pack-grid-btn"></router-link>
                  </div>
                </div>
              </li>
              <li>
                <div class="land-pack-grid">
                  <div class="land-pack-grid-img">
                    <img src="../assets/images/que_ans.jpg" alt="">
                  </div>
                  <div class="land-pack-grid-text">
                    <h5>{{ jobName }}</h5>
                    <router-link to="/manpower-jobs" class="land-pack-grid-btn"></router-link>
                  </div>
                </div>
              </li>
              <md-dialog :md-active.sync="showDialog">
                  
                <md-button class="md-primary" @click="country('India')">India</md-button>
                <md-button class="md-primary" @click="country('International')">International</md-button>
              </md-dialog>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="cl"></div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            manpowers: [],
            showDialog: false,
            countryType: '',
            jobName: 'Jobs',
            imagePath: process.env.VUE_APP_API_URL
        }
    },
    created() {
      this.countryType = localStorage.getItem("ManpowerCountryType");
      if(! this.countryType)
      {
        this.showDialog = true;
      }
      if(this.countryType == 'International')
      {
        this.jobName = "CREW";
      }
        this.axios
            .get(process.env.VUE_APP_API_URL +'api/manpower-category')
            .then(response => {
                this.manpowers = response.data['menpower_category'];
            });
    },
    methods: {
            country(id) {
              this.loading = true
              localStorage.setItem("ManpowerCountryType", id);
              window.location.reload();
            },
          }
}

</script>