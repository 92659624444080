<template>
    <div>
        <div class="cl"></div>
<div class="main bgF6F9FC">
  <div class="container">
    <div class="row">
      <div class="col-md-12">     
        <div class="top-cat-sec">
          <div class="home-tit">
            <h2><span>Select Machine for</span></h2>
          </div>
          <div class="land-pack">
            <ul>
              <li>
                <div class="land-pack-grid">
                  <div class="land-pack-grid-img">
                    <img src="../assets/img/machine_rent.png" alt="">
                  </div>
                  <div class="land-pack-grid-text">
                    <h5>Spare Capacity</h5>
                    <a @click="viewMachine('Rent','For Job Work')" class="land-pack-grid-btn"></a>
                  </div>
                </div>
              </li>
              <li>

                <div class="land-pack-grid">
                  <div class="land-pack-grid-img">
                    <img src="../assets/img/machine_sale.png" alt="">
                  </div>
                  <div class="land-pack-grid-text">
                    <h5>Buy Machine</h5>
                    <a @click="viewMachine('Sell','Buy Machine')" class="land-pack-grid-btn"></a>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="cl"></div>
    </div>
</template>

<script>
export default {
   
    methods: {
            viewMachine(type,name) {
              sessionStorage.setItem("machineType", type);
              sessionStorage.setItem("machineName", name);
              this.$router.push({ name: "Machine" });
            },
            

        }
}
</script>