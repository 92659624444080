<template>
	<div>
		<div v-if="loading">
                        <div style="position: fixed; top:0; bottom:0; width:100%;background-color:#efefef;opacity:0.4;z-index:99;margin: 0 auto;text-align:center;">
            <img style="position:relative;top:45%;opacity:1;z-index:100;" :src="'/images/loader.gif'" alt="" />
            </div>
        </div>
<div class="cl"></div>
<div class="trade-sec">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="home-tit">
          <h2><span>Tool & Die</span></h2>
  <div class="container">
    <div class="row">
      <div class="col-md-3"></div>
      <div class="col-md-6">
          <input type="text" v-model="keyword" v-on:input="searchProduct" name="search" class="form-control" placeholder="search e.g. Company Name, Location">
      </div>
      <div class="col-md-2"></div>
    </div>
  </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="toolDies.length">
      <div class="tab-content">
        <div class="tab-pane active" id="home-v">
          <div class="col-md-6" v-for="(toolDie , index) in toolDies" v-bind:key="index">
            <a @click="viewProduct(toolDie.id)">
            <div class="hm-shop-itm">
              <div v-if="toolDie.logo" class="hm-shop-img">
                <img :src="imgPath+toolDie.logo" alt="" class="img-responsive">
              </div>
              <div v-else class="hm-shop-img">
                <img src="./../assets/img/logo.png" alt="" class="img-responsive">
              </div>
              <div class="hm-shop-cnt">
                <div class="sp-tt">
                  <h4>{{ toolDie.name}}</h4>
                </div>
                <div class="sop-nm">
                  <span class="product-price"><i class="fa fa-map-marker"></i> {{ toolDie.address}}</span>
                  <p>{{ toolDie.city}}, {{ toolDie.state}} {{ toolDie.country.name}}</p>
                </div>
              </div>
            </div>
          </a>
          </div>
        </div>
      </div>
    </div>
    <div v-else><center><b>No Data Found</b></center></div>
  </div>
</div>
<div class="cl"></div>
	</div>
</template>
<script>
export default {
	data() {
        return {
            toolDies: [],
            imgPath: process.env.VUE_APP_API_URL,
            loading: false,
            param : '',
            keyword : null
        }
    },
    created() {
        this.loading = true
        this.axios
        .get(process.env.VUE_APP_API_URL+"api/tool-room-list")
          .then(response => {
            this.toolDies = response.data.company;
        })
        .finally(() => this.loading = false);
        
    },
    methods: {
            viewProduct(id) {
              sessionStorage.setItem("toolDie", id);
              this.$router.push({ name: "toolDieDetails" });
            },
            searchProduct() {
              this.loading = true
              this.axios
              .get(process.env.VUE_APP_API_URL+"api/tool-room-search?keyword="+this.keyword)
                .then(response => {
                  this.toolDies = response.data.company;
              })
              .finally(() => this.loading = false);
            }
        }
};
</script>

<style>
  #exzoom {
    width: 460px;
  }
  .hidden { display: none; }
</style>