<template>
	<div>
		<div v-if="loading">
                        <div style="position: fixed; top:0; bottom:0; width:100%;background-color:#efefef;opacity:0.4;z-index:99;margin: 0 auto;text-align:center;">
            <img style="position:relative;top:45%;opacity:1;z-index:100;" :src="'/images/loader.gif'" alt="" />
            </div>
        </div>
		<div class="adult-title"></div>
<div class="main" v-for="(extruderMachine , index) in extruderMachines" v-bind:key="index">
  <div class="container wrapper">
    <div class="row">
      <div class="col-md-5">
        <VueSlickCarousel :arrows="true" :dots="true" :autoplay="true" :autoplaySpeed="2000">
          <div><img :src="imgPath+extruderMachine.image1"/></div>
          <div v-if="extruderMachine.image2"><img :src="imgPath+extruderMachine.image2"/></div>
          <div v-if="extruderMachine.image3"><img :src="imgPath+extruderMachine.image3"/></div>
          <div v-if="extruderMachine.image4"><img :src="imgPath+extruderMachine.image4"/></div>
        </VueSlickCarousel>
      </div>
      <div class="col-md-7">
        <div class="packs-contant">
          <h3 style="margin-top:0;">{{ extruderMachine.name }}</h3>
          <table class="table table-bordered">
            <tbody>
              <tr>
              <th width="40%">Ref. ID</th>
              <td>{{ extruderMachine.id }}</td>
            </tr>
             <tr>
              <th width="30%">Machine Type</th>
              <td>{{ extruderMachine.machine_type }}</td>
            </tr>
             <tr>
              <th>Extrusion Capacity</th>
              <td>{{ extruderMachine.extrusion_capacity }} Kg/Hr</td>
            </tr>
            <tr>
              <th>Platen Size(mm)</th>
              <td>Length: {{ extruderMachine.screw_size_diameter }} Width: {{ extruderMachine.screw_size_length }}</td>
            </tr>
            <tr>
              <th>Machine Drive </th>
              <td>{{ extruderMachine.machine_drive }}</td>
            </tr>
             <tr> 
              <th>Machine Operation</th>
              <td>{{ extruderMachine.machine_operation }}</td>
            </tr>
           <!-- <tr> 
              <th>Raw Material Feed</th>
              <td>{{ extruderMachine.raw_material_feed }}</td>
            </tr> -->
            <tr> 
              <th>Make</th>
              <td>{{ extruderMachine.make }}</td>
            </tr>
            <tr> 
              <th>Mfg Year</th>
              <td>{{ extruderMachine.mfg_year }}</td>
            </tr>
            <tr> 
              <th>Spare Capacity</th>
              <td>{{ extruderMachine.spare_capacity }} Hours/Month</td>
            </tr>
            <!--  <tr> 
              <th>Application</th>
              <td>{{ extruderMachine.application }}</td>
            </tr> -->
            <tr> 
              <th>Country</th>
              <td v-if="extruderMachine.country">{{ extruderMachine.country.name }}</td>
              <td v-else></td>
            </tr>
            <tr> 
              <th>State/Province</th>
              <td>{{ extruderMachine.state }}</td>
            </tr>
            <tr> 
              <th>City</th>
              <td>{{ extruderMachine.city }}</td>
            </tr>
            <tr> 
              <th>Pincode</th>
              <td>{{ extruderMachine.zip_code }}</td>
            </tr>
            <tr>
              <td colspan="2">
            <b>Description</b>
          <p>{{ extruderMachine.description }}</p>
          </td>
          </tr>
          </tbody>
        </table>
            <table class="table table-bordered">
              <tbody>
                <tr style="background-color:green;color:white;">
                <td colspan="2">View Seller Info</td>
              </tr>
            <tr>
              <th width="30%"><img src="../assets/img/usericon.png" class="usrimg"> </th>
              <td><b>{{ extruderMachine.sellername }} </b> 
                <br> {{ extruderMachine.sellercompany_name }}
                <br> <p v-if="showInfo">{{ extruderMachine.sellermobile }} 
                  <br> {{ extruderMachine.selleremail }}</p>
                  <button v-if="showInfoButton" v-on:click="showInfo = true;showInfoButton = false" class="btn btn-success">View Contact Info</button>
                  &nbsp;
                  <button @click.prevent="openSideElement(extruderMachine.sellerid)" class="btn btn-info">Chat With Seller</button>
                  <div id="app">
                    <!-- <button class="btn btn-info" @click.prevent="openSideElement()">Chat</button> -->
                    <div class="side-element" :class="open ? 'show-element' : ''">
                      <div class="chat-head">
                        <div>
                          <div class="_icon">
                            <img src="../assets/img/usericon.png">
                            <div class="uoffline" v-if="online_status=='online'"></div>
                            <p>Last Seen: {{ lastSeen | formatDateTime }}</p>
                          </div>
                        </div>
                        <div style="width: 326px;">
                          <h4>{{ extruderMachine.sellername }} <!-- Last Seen: {{ lastSeen | formatDateTime }} --></h4>
                          <p></p>
                        </div>
                        <div><span class="close-btn2" @click.prevent="close()">x</span></div>
                      </div>
                      <div class="content1">
                        <div class="chat">
                          <div id="showallchatmessage" class="chat__wrapper">
                            <div class="chat__wrapper" v-for="(chat , index) in chats" v-bind:key="index">
                              <div class="chat__message" v-if="fromUser == chat.from_user">
                                <div>{{ chat.message }}</div>
                                <div class="date">
                                  {{ chat.date | formatDateTimeMonth }}
                                </div>
                              </div>
                              <div class="chat__message chat__message-own" v-else>
                                <div>{{ chat.message }}</div>
                                <div class="date">
                                  {{ chat.date | formatDateTimeMonth }}
                                </div>
                                <span style="color:#53bdeb" v-if="chat.read==1"><svg viewBox="0 0 16 15" width="16" height="15" class=""><path fill="currentColor" d="m15.01 3.316-.478-.372a.365.365 0 0 0-.51.063L8.666 9.879a.32.32 0 0 1-.484.033l-.358-.325a.319.319 0 0 0-.484.032l-.378.483a.418.418 0 0 0 .036.541l1.32 1.266c.143.14.361.125.484-.033l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0-.478-.372a.365.365 0 0 0-.51.063L4.566 9.879a.32.32 0 0 1-.484.033L1.891 7.769a.366.366 0 0 0-.515.006l-.423.433a.364.364 0 0 0 .006.514l3.258 3.185c.143.14.361.125.484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z"></path></svg></span>
                                <span v-else><svg viewBox="0 0 16 15" width="16" height="15" class=""><path fill="currentColor" d="m15.01 3.316-.478-.372a.365.365 0 0 0-.51.063L8.666 9.879a.32.32 0 0 1-.484.033l-.358-.325a.319.319 0 0 0-.484.032l-.378.483a.418.418 0 0 0 .036.541l1.32 1.266c.143.14.361.125.484-.033l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0-.478-.372a.365.365 0 0 0-.51.063L4.566 9.879a.32.32 0 0 1-.484.033L1.891 7.769a.366.366 0 0 0-.515.006l-.423.433a.364.364 0 0 0 .006.514l3.258 3.185c.143.14.361.125.484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z"></path></svg></span>
                              </div>
                            </div>
                          </div>
                        </div> 
                        <div class="chat__form">
                          <form id="chat__form" @submit.prevent="sendMessage">
                            <input id="message_text" type="text" placeholder="Type your message here ..." v-model="message.message" required> <button type="submit"><img src="https://codersinn.club/webassets/live/assets/images/send.png" alt="" class="send-img"></button>
                          </form> 
                          <span id="showmsgerror" class="text-danger"></span>
                        </div> 
                        <div id="result"></div>
                      </div>
                    </div>
                  </div>
              </td>
            </tr>
            
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="main">
  <div class="container" v-if="similarMachines">
    <div class="row">
      <div class="col-md-12">
        <div class="home-tit">
          <h2><span>Similar Machine</span></h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3" v-for="(similarMachine , index) in similarMachines" v-bind:key="index">
        <div class="grid-item">
          <a @click="viewProduct(similarMachine.id)">
          <div class="imgholder ">
              <img :src="imgPath+similarMachine.image1">
          </div>
          <div class="product_footer">
            <div class="grid_product_header">
              <span class="name">{{ similarMachine.name}} </span>
              <div class="product-location-grid">{{ similarMachine.city }} , 
             <span v-if="similarMachine.country"> {{ similarMachine.country.name }} 
             </span>
              </div>
            </div>
            <div class="product_footer-info">
              <div class="product-price-grid">Model: {{ similarMachine.make }} <br>
                Year: {{ similarMachine.mfg_year }}</div>
              <div>{{ similarMachine.machine_type }}</div>
            </div>
          </div>
          </a>
        </div>
      </div>
      
      
    </div>
  </div>
</div>
<div class="cl"></div>
	</div>
</template>
<script>
import VueSlickCarousel from 'vue-slick-carousel'
  import 'vue-slick-carousel/dist/vue-slick-carousel.css'
  import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
export default {
  components: { VueSlickCarousel },
  mounted: function () {
  this.timer = setInterval(() => {
    this.getMessage()
  }, 5000)
},
	data() {
        return {
            extruderMachines: [],
            similarMachines: [],
            imgPath: process.env.VUE_APP_API_URL,
            loading: false,
            showInfo: false,
            showInfoButton:true,
            open: false,
            timer: null,
            chats: [],
            fromUser: '',
            userName: '',
            userImage: '',
            lastSeen: '',
            online_status: 'offline',
            message: {
              sender_id: sessionStorage.getItem("chatId"),
              listing_id: sessionStorage.getItem("extruderMachine"),
              type: 'Extruder Machine',
              message: ''
            },
            listing_id: sessionStorage.getItem("extruderMachine")
        }
    },
    created() {
        this.loading = true
        this.axios
        .get(process.env.VUE_APP_API_URL+"api/extruder-machine-details?id="+sessionStorage.getItem("extruderMachine"))
        .then(response => {
            this.extruderMachines = response.data.extruder_machine;
            this.similarMachines = response.data.similar_machines;
            sessionStorage.setItem("chatListingId",this.listing_id)
            sessionStorage.setItem("chatListingType","Extruder Machine")
        })
        .finally(() => this.loading = false);

    },
    methods: {
            viewProduct(id) {
              sessionStorage.setItem("extruderMachine", id);
              //this.$router.push({ name: "ExtruderMachineDetails" });
               window.location.reload();
            },
            openSideElement: function(id){
                this.open = true;
                this.message.sender_id = id
                this.fromUser = id
                sessionStorage.setItem("chatId",id)

            },
            close: function(){
                this.open = false;
            },
            sendMessage() {
                this.loading = true
                this.success = null
                this.errors = {}
                this.axios
                    .post(process.env.VUE_APP_API_URL+"api/chat-send-message", this.message)
                    .then(response => {
                        if(response.data.error==true){
                            this.errors = response.data.validate
                        }else{
                            this.success = response.data.msg
                            this.chats = []
                            this.message.message = ''
                          this.axios
                          .get(process.env.VUE_APP_API_URL+"api/chat-message-listingwise?chat_user_id="+sessionStorage.getItem("chatId")+"&listing_id="+sessionStorage.getItem("chatListingId")+"&type="+sessionStorage.getItem("chatListingType"))
                          .then(response => {
                              this.chats = response.data.chats;
                              this.lastSeen = response.data.chatUserDetails[0].last_seen;
                          })
                        }
                    })
                    .catch(error => console.log(error))
                    .finally(() => this.loading = false)
            },
            getMessage() {
                this.chats = []
                          this.axios
                          .get(process.env.VUE_APP_API_URL+"api/chat-message-listingwise?chat_user_id="+sessionStorage.getItem("chatId")+"&listing_id="+sessionStorage.getItem("chatListingId")+"&type="+sessionStorage.getItem("chatListingType"))
                          .then(response => {
                              this.chats = response.data.chats;
                              this.lastSeen = response.data.chatUserDetails[0].last_seen;
                              this.online_status = response.data.online_status;
                          })
            }
        },
        getMessage() {
                this.chats = []
                          this.axios
                          .get(process.env.VUE_APP_API_URL+"api/chat-message-listingwise?chat_user_id="+sessionStorage.getItem("chatId")+"&listing_id="+sessionStorage.getItem("chatListingId")+"&type="+sessionStorage.getItem("chatListingType"))
                          .then(response => {
                              this.chats = response.data.chats;
                              this.lastSeen = response.data.chatUserDetails[0].last_seen;
                              this.online_status = response.data.online_status;
                          })
            }
};
</script>

<style>
  #exzoom {
    width: 460px;
    /*height: 400px;*/
  }
  .hidden { display: none; }
  /*#service th,td {
    border-bottom: 1px solid #999;
  } */
  .usrimg { height: 90px !important; }
</style>
