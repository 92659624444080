<template>
	<div>
		<div v-if="loading">
                        <div style="position: fixed; top:0; bottom:0; width:100%;background-color:#efefef;opacity:0.4;z-index:99;margin: 0 auto;text-align:center;">
            <img style="position:relative;top:45%;opacity:1;z-index:100;" :src="'/images/loader.gif'" alt="" />
            </div>
        </div>
		  <div class="main bgF6F9FC">
  <div class="container">
    <div class="row">
      <div class="col-md-12" v-if="toolDies">     
        <div class="tab-content">
          <div class="tab-pane active" id="home-v">
            <div class="col-md-8">
              <div class="cnd-main">
                <div class="hm-shop-img">
                  <img src='./../assets/img/logo.png' class="img-responsive usrimg">
                </div>
                <div class="cad-cnt">
                  <div class="cnd-tt">
                    <h3>{{ toolDies.name }}</h3>
                  </div>
                  
                </div>
              </div>
              <div class="candidate-cnt">
                <div class="candidate-ditem">
                  <div class="candidate-ditem-tt">
                    
                  </div>
                  <div class="candidate-ditem-dd" >
                     <table class="display nowrap table table-hover table-striped table-bordered" cellspacing="0" width="100%">
                      <tr>
                         <td colspan="2" style="background-color:green;color:white"><h4>Description</h4></td>
                         </tr>
                         <tr>
                           <td colspan="2">{{ toolDies.description }}</td>
                         </tr>
                         <tr>
                        <th colspan="2" style="background-color:green;color:white"><h4>Tool Die Machines</h4></th>
                        </tr>
                      
                       
                     </table>
                    </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else><br><br><br><center><b>No Data Found</b></center><br><br></div>
    </div>
  </div>
</div>
<div class="cl"></div>
	</div>
</template>
<script>
export default {
	data() {
        return {
            toolDies: {},
            imgPath: process.env.VUE_APP_API_URL,
            loading: false,
            showInfo: false,
            showInfoButton:true
        }
    },
    created() {
        this.loading = true
        this.axios
        .get(process.env.VUE_APP_API_URL+"api/tool-room-details/"+sessionStorage.getItem("toolDie"))
        .then(response => {
          if(response.data.error==false){
            console.log(response.data.company);
            this.toolDies = response.data.company;
          }
        })
        .finally(() => this.loading = false);
    }
};
</script>

<style>
  #exzoom {
    width: 460px;
    /*height: 400px;*/
  }
  .hidden { display: none; }
  /*#service th,td {
    border-bottom: 1px solid #999;
  } */
  .usrimg { height: 90px !important; }
</style>