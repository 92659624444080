<template>
	<div>
		<div v-if="loading">
                        <div style="position: fixed; top:0; bottom:0; width:100%;background-color:#efefef;opacity:0.4;z-index:99;margin: 0 auto;text-align:center;">
            <img style="position:relative;top:45%;opacity:1;z-index:100;" :src="'/images/loader.gif'" alt="" />
            </div>
        </div>
<div class="cl"></div>
<div class="trade-sec">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="home-tit">
          <h2><span>Blow Molding Machine</span></h2>
  <div class="container">
    <div class="row">
      <div class="col-md-3"></div>
      <div class="col-md-6">
          <input type="text" v-model="keyword" v-on:input="searchProduct" name="search" class="form-control" placeholder="search e.g. Company Location Size">
      </div>
      <div class="col-md-2"></div>
    </div>
  </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="blowMoldingMachines.length">
      <div class="col-md-3" v-for="(blowMoldingMachine , index) in blowMoldingMachines" v-bind:key="index">
        <div class="grid-item" v-if="blowMoldingMachine">
          <a @click="viewProduct(blowMoldingMachine.id, blowMoldingMachine.machine_size)">
          <div class="imgholder ">
            <img :src="imgPath+blowMoldingMachine.image1">
          </div>
          <div class="product_footer">
            <div class="grid_product_header">
              <span class="name">{{ blowMoldingMachine.sellercompany_name }} </span>
              <div class="product-location-grid">{{ blowMoldingMachine.city }} , 
                <span v-if="blowMoldingMachine.country">{{ blowMoldingMachine.country.name }} </span></div>
            </div>
            <div class="product_footer-info">
              <div class="product-price-grid">Model: {{ blowMoldingMachine.make }} <br>
                Year: {{ blowMoldingMachine.mfg_year }}</div>
              <div>{{ blowMoldingMachine.accumulator_capacity }} Ltrs</div>
              
            </div>
          </div>
          </a>
        </div>
        <div v-else>Record Not Found</div>
      </div>
    </div>
    <div v-else><center><b>No Data Found</b></center></div>
  </div>
</div>
<div class="cl"></div>
	</div>
</template>
<script>
export default {
	data() {
        return {
            blowMoldingMachines: [],
            imgPath: process.env.VUE_APP_API_URL,
            loading: false,
            param : '',
            keyword: null
        }
    },
    created() {
        this.loading = true
        this.axios
        .get(process.env.VUE_APP_API_URL+"api/blow-molding-machine-list?type="+sessionStorage.getItem("machineType"))
          .then(response => {
            this.blowMoldingMachines = response.data.blow_modling_machine;
        })
        .finally(() => this.loading = false);
        
    },
    methods: {
            viewProduct(id,size) {
              sessionStorage.setItem("blowMoldingMachine", id);
              sessionStorage.setItem("blowMoldingMachineSize", size);
              this.$router.push({ name: "BlowMoldingMachineDetails" });
            },
            searchProduct() {
              this.loading = true
              this.axios
              .get(process.env.VUE_APP_API_URL+"api/blow-molding-machine-search?keyword="+this.keyword+"&type="+sessionStorage.getItem("machineType"))
                .then(response => {
                  this.blowMoldingMachines = response.data.blow_modling_machine;
              })
              .finally(() => this.loading = false);
            }
        }
};
</script>

<style>
  #exzoom {
    width: 460px;
  }
  .hidden { display: none; }
</style>