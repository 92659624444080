<template>
    <div>
        <div v-if="loading">
                        <div style="position: fixed; top:0; bottom:0; width:100%;background-color:#efefef;opacity:0.4;z-index:99;margin: 0 auto;text-align:center;">
            <img style="position:relative;top:45%;opacity:1;z-index:100;" src="./../../assets/images/loader.gif" alt="" />
            </div>
        </div>
        <div class="profile-sec">
        <div class="container-fluid">
            <div class="row">
                 <div class="col-md-2 zero col-xs-12">
                    <sidebar/>
                </div>
                <div class="col-md-10 col-xs-12">
                    <div class="profl-sec">
                        <div class="h3-title">
                            <h3>My Blow Molding Machines</h3>
                        </div>
                            <div class="profile-details" >
                                <div class="col-md-12 p_t_10">
                                    <div class="alert alert-success text-center" v-if="success!=null">
                                        {{ success }}
                                    </div>
                                    <div class="row" v-if="blowMachines.length">
      <div class="col-md-3" v-for="(blowMachine , index) in blowMachines" v-bind:key="index">
        <div class="grid-item" v-if="blowMachine">
          <div class="imgholder ">
            <a @click="viewMachine(blowMachine.id)">
               <img :src="imgPath+blowMachine.image1">
              <div class="top-right">
                 <div class="btn btn-warning" v-if="blowMachine.approved=='Pending'">
                    Under Review
                </div>
                <div class="btn btn-success" v-if="blowMachine.approved=='Approved'">
                    Published
                </div>
            </div>
            </a>
          </div>
          <div class="product_footer">
            <div class="grid_product_header">
              <span class="name">{{ blowMachine.sellercompany_name }} </span>
            </div>
                <div class="product_footer-info">
                <div>{{ blowMachine.city }} {{ blowMachine.state }},{{ blowMachine.country.name }}</div>
              <div class="product-price-grid">{{ blowMachine.spare_capacity }} Hours/Month</div>
              <div>{{ blowMachine.machine_size }} Ton <button class="btn btn-danger" @click="deleteMachine(blowMachine.id)" title="Delete"><i class="fa fa-trash"></i></button></div>
            </div>
          </div>
        </div>
        <div v-else>Record Not Found</div>
      </div>
      
    </div>
    <div v-else><center><b>No Data Found</b></center></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="cl"></div>
    </div>
</template>
<script>
import SideBar from './../SideBar.vue';

export default {
    components: {
        'sidebar': SideBar
    },
        
    data() {
        return {
            blowMachines: {},
            imgPath: process.env.VUE_APP_API_URL,
            loading: false
        }
    },
    created() {
        this.loading = true
        this.axios
        .get(process.env.VUE_APP_API_URL+"api/my-blow-molding-machine")
        .then(response => {
            this.blowMachines = response.data.injection_modling_machine;
        })
        .finally(() => this.loading = false);
    },
    methods: {
            viewMachine(id) {
              sessionStorage.setItem("MyBlowMachine", id);
              this.$router.push({ name: "MyBlowMoldingMachineDetails" });
            },
            deleteMachine(id) {
                this.loading = true
              this.axios
                .delete(process.env.VUE_APP_API_URL+"api/blow-molding-machine-delete?id="+id)
                .then(response => {
                    if(response.data.error==false){
                        window.location.reload();
                        /*let i = this.blowMachines.map(blowMachine => blowMachine.id).indexOf(id); // find index of your object
                        this.blowMachines.splice(i, 1)*/
                    }
                    
                        
                })
                .finally(() => this.loading = false);
            }
        }
};
</script>
<style type="text/css">
    .top-right {
  position: absolute;
  top: 8px;
  right: 16px;
  color: red;
  text-align: right;
}
</style>