<template>
	<div>
		<div v-if="loading">
                        <div style="position: fixed; top:0; bottom:0; width:100%;background-color:#efefef;opacity:0.4;z-index:99;margin: 0 auto;text-align:center;">
            <img style="position:relative;top:45%;opacity:1;z-index:100;" :src="'/images/loader.gif'" alt="" />
            </div>
        </div>
<div class="cl"></div>
<div class="trade-sec">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="home-tit">
          <h2><span>Injection Molding Machine</span></h2>
  <div class="container">
    <div class="row">
      <div class="col-md-3"></div>
      <div class="col-md-6">
          <input type="text" v-model="keyword" v-on:input="searchProduct" name="search" class="form-control" placeholder="search e.g. Company Location Size">
      </div>
      <div class="col-md-2"></div>
    </div>
  </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="injectionMachines.length">
      <div class="col-md-3" v-for="(injectionMachine , index) in injectionMachines" v-bind:key="index">
        <div class="grid-item" v-if="injectionMachine">
          <a @click="viewProduct(injectionMachine.id, injectionMachine.machine_size)">
          <div class="imgholder ">
              <img :src="imgPath+injectionMachine.image1">
          </div>
          <div class="product_footer">
            <div class="grid_product_header">
              <span class="name">{{ injectionMachine.sellercompany_name }} </span>
              <div class="product-location-grid">{{ injectionMachine.city }} , {{ injectionMachine.country.name }}</div>
            </div>
            <div class="product_footer-info">
              <div class="product-price-grid">Model: {{ injectionMachine.make }} <br>
                Year: {{ injectionMachine.mfg_year }}
              </div>
              <div>{{ injectionMachine.machine_size }} Ton</div>
            </div>
          </div>
          </a>
        </div>
        <div v-else>Record Not Found</div>
      </div>
    </div>
   <div v-else><center><b>No Data Found</b></center></div>
  </div>
</div>
<div class="cl"></div>
	</div>
</template>
<script>
export default {
	data() {
        return {
            injectionMachines: [],
            imgPath: process.env.VUE_APP_API_URL,
            loading: false,
            param : '',
            keyword : null,
            machinetype : ''
        }
    },
    created() {
        this.loading = true
        this.machinetype = sessionStorage.getItem("machineType");
        if(this.machinetype == '')
        {
          this.$router.push({ name: "MachineType" });
        }
        this.axios
        .get(process.env.VUE_APP_API_URL+"api/injection-modling-machine-list?type="+sessionStorage.getItem("machineType"))
          .then(response => {
            this.injectionMachines = response.data.injection_modling_machine;
        })
        .finally(() => this.loading = false);
        
    },
    methods: {
            viewProduct(id,size) {
              sessionStorage.setItem("injectionMachine", id);
              sessionStorage.setItem("injectionMachineSize", size);
              this.$router.push({ name: "InjectionMachineDetails" });
            },
            searchProduct() {
              this.loading = true
              this.axios
              .get(process.env.VUE_APP_API_URL+"api/injection-molding-machine-search?keyword="+this.keyword+"&type="+sessionStorage.getItem("machineType"))
                .then(response => {
                  this.injectionMachines = response.data.injection_modling_machine;
              })
              .finally(() => this.loading = false);
            }

        }
};
</script>

<style>
  #exzoom {
    width: 460px;
  }
  .hidden { display: none; }
</style>